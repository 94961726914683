<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead class="mb-3">
                    <tr>
                        <th class="text-left font-weight-bold black--text">
                            Asset
                        </th>
                        <th
                            v-if="!isDraft && !isDeleted"
                            class="text-left font-weight-bold black--text"
                        >
                            {{ isCanceled ? 'Last Sale Type' : 'Sale Type' }}
                        </th>
                        <slot name="columns" />
                        <th class="text-left font-weight-bold black--text">
                            Asset type
                        </th>
                        <th class="text-left font-weight-bold black--text">
                            Owner
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <router-link
                        v-for="asset in assets"
                        :key="asset.id"
                        :to="`/assets/short-info/${asset.id}/${asset.kind}`"
                        class="asset-item"
                        tag="tr"
                    >
                        <th>
                            <div class="d-flex align-center justify-start py-3">
                                <div class="mr-5">
                                    <v-icon size="25">
                                        mdi-file-document-outline
                                    </v-icon>
                                </div>

                                <div class="mr-7">
                                    <app-storage-image
                                        :aspect-ratio="4/3"
                                        width="70"
                                        :storage-key="asset.images.mainImage"
                                    />
                                </div>

                                <span
                                    v-if="asset.kind === 'Basic'"
                                    class="text-decoration-underline"
                                >
                                    {{ asset.title }}
                                </span>
                                <span
                                    v-else
                                    class="text-decoration-underline"
                                >
                                    {{ getAddress(asset.address) }}
                                </span>
                            </div>
                        </th>
                        <th
                            v-if="!isDraft && !isDeleted"
                        >
                            {{ getSaleType(asset.sale ? asset.sale.kind : '') }}
                        </th>
                        <slot
                            name="column-value"
                            :asset="asset"
                        />

                        <th>
                            {{ asset.kind }}
                        </th>

                        <th>
                            <span v-if="asset.agent">
                                {{ `${asset.agent.firstName} ${asset.agent.lastName}` }}
                            </span>
                        </th>
                    </router-link>
                </tbody>
            </template>
        </v-simple-table>
        <app-loader v-if="loading" />
        <p
            v-else-if="!loading && !assets.length"
            class="pa-6"
        >
            You don't have any assets yet.
        </p>
    </div>
</template>


<script>
import AppStorageImage from '@/components/App/AppStorageImage';

export default {
    components: { AppStorageImage },
    props: {
        assets: {
            type: Array,
            default: () => [],
        },
        loading: Boolean,
        isDraft: Boolean,
        isSold: Boolean,
        isDeleted: Boolean,
        isCanceled: Boolean,
    },
    methods: {
        getAddress(address) {
            return address ? Object.values(address).filter(Boolean).join(', ') : 'No address';
        },
        getSaleType(status) {
            const saleTypes = {
                listingSale: 'Listing',
                englishAuction: 'English auction',
            };

            return saleTypes[status] || '';
        },
    },
};
</script>

<style scoped>
.asset-item {
    cursor: pointer;
}
</style>