import { API } from '@aws-amplify/api';
import { filtersFields } from '@/utils/filtersFields';

export default {
    listAssets(limit, nextToken, filter) {
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.API.endpoints[0].endpoint;
        const query = `query listAssets($limit: Int, $nextToken: String, $filter: AssetFilter) {
            listAssets(limit: $limit, nextToken: $nextToken, filter: $filter) {
                ${filtersFields[filter]}
            }
        }`;

        return API.graphql({
            query,
            variables: {
                limit,
                nextToken,
                filter: {
                    status: filter,
                },
            },
        });
    },
};
