<template>
    <v-img
        v-bind="$attrs"
        :src="src"
        alt=""
        class="grey lighten-2"
    >
        <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                />
            </v-row>
        </template>
    </v-img>
</template>

<script>
import { Storage } from 'aws-amplify';
import { Utils } from '@/utils';

export default {
    name: 'AppStorageImage',

    props: {
        storageKey: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            src: null,
        };
    },

    watch: {
        storageKey: {
            handler() {
                const config = {};

                if (!this.storageKey.includes('/')) {
                    config.level = 'public';

                    Storage.get(this.storageKey, {
                        ...config,
                    }).then((res) => {
                        this.src = res;
                    });
                } else {
                    const parsedKey = Utils.parseFileKey(this.storageKey);
                    config.level = parsedKey.level;
                    config.identityId = parsedKey.identityId;

                    Storage.get(parsedKey.key, {
                        ...config,
                    }).then((res) => {
                        this.src = res;
                    });
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped></style>