import { DELETED, ACTIVE, DRAFT, SOLD, UNLISTED } from '@/utils/filters';

const generalFields = `
                    id
                    kind
                    images {
                        mainImage
                    }
                    agent {
                        firstName
                        lastName
                    }`;

function getQueryFields(additional) {
    return `nextToken
            count
            data {
                ... on BasicAsset {
                    title
                    ${generalFields}
                    ${additional || ''}
                }
                ... on ResidentialAsset {
                    address {
                        address
                        city
                        state
                        zip
                    }
                    ${generalFields}
                    ${additional || ''}
                }
                ... on CommercialAsset {
                    address {
                        address
                        city
                        state
                        zip
                    }
                    ${generalFields}
                    ${additional || ''}
                }
                ... on LandAsset {
                    address {
                        address
                        city
                        state
                        zip
                    }
                    ${generalFields}
                    ${additional || ''}
                }
            }`;
}

export const filtersFields = {
    [DRAFT]: getQueryFields(),
    [ACTIVE]: getQueryFields(`
                sale {
                    id
                    kind
                    englishAuction {
                        endDate
                        pendingParticipations
                        approvedParticipations
                    }
                    status
                }
            `),
    [SOLD]: getQueryFields(`
                sale {
                    kind
                    status
                }
            `),
    [UNLISTED]: getQueryFields(`
                sale {
                    kind
                    status
                }
            `),
    [DELETED]: getQueryFields(`
                sale {
                    kind
                    status
                }
            `),
};
