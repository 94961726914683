<template>
    <v-card class="listings-card overflow-auto">
        <div class="d-flex justify-between align-center black">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                @change="tabChanged"
            >
                <v-tab
                    v-for="t in tabs"
                    :key="t.value"
                >
                    {{ t.title }}
                </v-tab>
            </v-tabs>
            <v-btn
                tile
                router
                to="/assets/new"
                color="#EC3D3D"
                light
                class="mr-5 white--text"
            >
                <v-icon class="mr-3">
                    mdi-folder-plus
                </v-icon>Create new asset
            </v-btn>
        </div>
        <v-tabs-items
            v-model="tab"
            class="tab-item-wrapper px-6 my-6"
            @scroll.native="onScroll($event)"
        >
            <v-tab-item>
                <ListingTable
                    :assets="assets"
                    is-draft
                    :loading="loading"
                />
            </v-tab-item>

            <v-tab-item>
                <ListingTable
                    :assets="assets"
                    :loading="loading"
                >
                    <template #columns>
                        <th class="text-left font-weight-bold black--text">
                            Stage
                        </th>
                        <th class="text-left font-weight-bold black--text">
                            End date
                        </th>
                        <th class="text-left font-weight-bold black--text">
                            Bidders
                        </th>
                    </template>
                    <template #column-value="{asset}">
                        <th>{{ getSaleStatusTitle(asset.sale ? asset.sale.status : '') }}</th>
                        <th class="date-column">
                            {{ getEndDate(asset.sale) }}
                        </th>
                        <th v-if="asset.sale && asset.sale.kind === saleKinds.LISTING_SALE">
                            -
                        </th>
                        <th
                            v-else
                            class="text-no-wrap"
                        >
                            <template
                                v-if="asset.sale && asset.sale.englishAuction && asset.sale.englishAuction.pendingParticipations"
                            >
                                <span class="red--text">{{ asset.sale.englishAuction.pendingParticipations }}</span> +
                            </template>
                            {{ (asset.sale && asset.sale.englishAuction && asset.sale.englishAuction.approvedParticipations)
                                ? asset.sale.englishAuction.approvedParticipations : 0 }}
                        </th>
                    </template>
                </ListingTable>
            </v-tab-item>

            <v-tab-item>
                <ListingTable
                    :assets="assets"
                    is-canceled
                    :loading="loading"
                />
            </v-tab-item>

            <v-tab-item>
                <ListingTable
                    :assets="assets"
                    :loading="loading"
                    is-sold
                >
                    <template #columns>
                        <th class="text-left font-weight-bold black--text">
                            Date of sale
                        </th>
                    </template>
                    <template #column-value>
                        <th>-</th>
                    </template>
                </ListingTable>
            </v-tab-item>

            <v-tab-item>
                <ListingTable
                    :assets="assets"
                    is-deleted
                    :loading="loading"
                />
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import assetSubscriptions from '@/services/subscriptions/asset';
import { Utils } from '@/utils';
import { saleKinds } from '@/constants/saleKinds';
import { tabs } from '@/utils/constants';
import ListingTable from '@/components/AdminDashboard/AdminDashboardListing/ListingTable';
import { ACTIVE } from '@/utils/filters';
import { getSaleStatusTitle } from '@/constants/saleStatuses';
import listingsApi from '@/services/apiCalls/listings';
import saleSubscriptions from '@/services/subscriptions/sale';

export default {
    name: 'AdminDashboardListings',

    components: { ListingTable },

    data: () => ({
        tab: tabs.findIndex((el) => el.title === 'Active'),
        assets: [],
        assetsPerPage: 10,
        nextToken: '',
        loading: true,
        lastPage: false,
        count: null,
        tabs,
        saleKinds,
        subscriptions: [],
    }),

    computed: {
        currentTab() {
            return this.tabs[this.tab].value;
        },
        isActiveTab() {
            return this.currentTab === ACTIVE;
        },
        isUserTypeFree() {
            return this.$store.getters.isUserType;
        },
    },

    created() {
        // const switchStatusSubscription = assetSubscriptions
        //     .onMySwitchStatus(this.$store.state.user.sub)
        //     .subscribe(this.onMySwitchStatus);
        //
        // this.$once('hook:beforeDestroy', () => {
        //     switchStatusSubscription.unsubscribe();
        // });
    },

    mounted() {
        if (this.$route.query.tab) this.tab = tabs.findIndex((el) => el.title === this.$route.query.tab);
        setTimeout(
            () =>
                this.getAssets(this.currentTab).then(() => {
                    if (this.isUserTypeFree) {
                        this.$store.commit('openUserBuyerModal');
                    }
                }),
            5000,
        );
    },

    beforeDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    },

    methods: {
        async fetchAssets({ limit, nextToken, filter }) {
            try {
                return await listingsApi.listAssets(limit, nextToken, filter);
            } catch (error) {
                this.$store.dispatch('handleError', error);
            }
        },
        tabChanged() {
            this.refreshTab();
            this.$router.push({ query: { tab: this.tabs[this.tab].title } });
            setTimeout(() => this.getAssets(this.currentTab), 5000);
        },
        refreshTab() {
            this.loading = true;
            this.assets = [];
            this.nextToken = '';
            this.lastPage = false;
            this.subscriptions.forEach((s) => s.unsubscribe());
            this.subscriptions = [];
        },
        async getAssets(filter) {
            this.loading = true;
            try {
                const { data } = await this.fetchAssets({
                    limit: this.assetsPerPage,
                    nextToken: this.nextToken,
                    filter,
                });
                if (filter !== this.currentTab) return;
                this.nextToken = data.listAssets.nextToken;
                this.assets = [...this.assets, ...data.listAssets.data];
                data.listAssets.data.forEach((asset) => {
                    if (this.isActiveTab) {
                        if (asset.sale?.kind === saleKinds.ENGLISH_AUCTION) {
                            this.subscribeOnSwitchEnglishAuctionStatus(asset);
                            this.subscribeOnEditAuctionSubscription(asset);
                            this.subscribeOnChangedSaleParticipation(asset);
                        }
                    }
                });
                if (!data.listAssets.nextToken) this.lastPage = true;
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },
        onScroll(e) {
            if (this.loading || this.lastPage) return;
            const isScrollOnBottom = e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight;
            if (isScrollOnBottom) this.getAssets(this.currentTab);
        },
        getEndDate(object) {
            if (!object || !object.englishAuction || object.kind !== saleKinds.ENGLISH_AUCTION) return '-';

            return object.englishAuction.endDate ? Utils.formatDate(object.englishAuction.endDate) : '-';
        },
        onMySwitchStatus(value) {
            console.log(value);
        },
        getSaleStatusTitle(status) {
            return getSaleStatusTitle(status);
        },
        subscribeOnSwitchEnglishAuctionStatus(asset) {
            const switchEnglishAuctionStatusSubscription = assetSubscriptions
                .switchEnglishAuctionStatus(asset.sale.id)
                .subscribe(
                    ({
                        value: {
                            data: { switchEnglishAuctionStatus },
                        },
                    }) => {
                        asset.sale.status = switchEnglishAuctionStatus.status;
                    },
                );

            this.subscriptions.push(switchEnglishAuctionStatusSubscription);
        },
        subscribeOnEditAuctionSubscription(asset) {
            const onEditAuctionSubscription = assetSubscriptions
                .onEditAuction(asset.sale.id)
                .subscribe(({ englishAuction, status }) => {
                    asset.sale.status = status;
                    asset.sale.englishAuction = {
                        ...asset.sale.englishAuction,
                        ...englishAuction,
                    };
                });

            this.subscriptions.push(onEditAuctionSubscription);
        },
        subscribeOnChangedSaleParticipation(asset) {
            const onChangedSaleParticipationSubscription = saleSubscriptions
                .onChangedSaleParticipation({ id: asset.sale.id })
                .subscribe(({ englishAuction }) => {
                    asset.sale.englishAuction = {
                        ...asset.sale.englishAuction,
                        ...englishAuction,
                    };
                });

            this.subscriptions.push(onChangedSaleParticipationSubscription);
        },
    },
};
</script>

<style lang="scss" scoped>
.tab-item-wrapper {
    max-height: 500px;
    overflow: auto;
}

.listings-card {
    & .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
        color: #ffffff;
        border-right: 1px solid #ffffff;
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
    .v-tabs {
        ::v-deep {
            .v-item-group[role='tablist'] {
                width: auto;
                margin-right: 0;
                max-width: unset;
            }
        }
        .v-tab--active {
            background-color: #fff;
            color: #000;
            pointer-events: none;
        }
    }
}
</style>